<template>
  <div id="about">
    <header>
      <div class="headerTop">
        <div class="container">
          <div class="logo">
            <h1>
              <img src="@/assets/images/logo.png" alt="logo" />
            </h1>
          </div>
          <ul>
            <li><router-link to="/">首页</router-link></li>
            <li><router-link to="wisdom">智量百业</router-link></li>
            <li><router-link to="shop">同城好店</router-link></li>
            <li><router-link to="assistant">抖微助手</router-link></li>
            <li><router-link to="flyfishoutlets">飞鱼系统</router-link></li>
            <li><router-link to="commerce">跨境电商</router-link></li>
            <!-- <li><router-link to="contact">联系我们</router-link></li> -->
            <li class="cur"><router-link to="about">关于我们</router-link></li>
          </ul>
        </div>
      </div>
      <img src="@/assets/images/about_banner1.jpg" alt="" />
    </header>
    <section class="about container">
      <div class="pd100">
        <img src="@/assets/images/about.png" alt="" />
      </div>
      <ul>
        <li>
          <h4>企业核心价值观</h4>
          <p>客户第一，正直进取</p>
          <p>合作创新，拥抱变化</p>
        </li>
        <li>
          <h4>企业愿景</h4>
          <p>让商业更简单</p>
        </li>
        <li>
          <h4>企业文化</h4>
          <p>简单、纯粹、激情</p>
        </li>
      </ul>
      <div class="pd100 about-us">
        <h4>关于漠阳风</h4>
        <p>
          漠阳风科技是中国领先的AI精准流量服务商，以及全域AI自动化营销服务商，专注于实体转型新电商AI流量变现服务。基于公司专业的AI短视频+AI直播工具，结合专业的全案策划能力和内容营销能力，帮助实体商家低成本获客，实现真正的降本增效。
        </p>
        <p>
          公司成立近5年时间，已成功帮助2000+实体店、300+工厂成功实现转型和流量变现。客户覆盖制造业、消费品源头工厂，以及餐饮、美业、旅游、休闲娱乐、教培、宠物、家装建材、商超、企业咨询等各行各业。
        </p>
        <!-- <img src="@/assets/images/about-fish.jpg" alt="飞鱼系统" />
        <p>
          飞鱼系统是漠阳风科技有限公司旗下的社交新零售电商平台，主营进口奥特莱斯折扣商品，以提升零售效率和降低运营成本为目的，以消费者为中心，为商家（包括线上各种流量主及线下店）提供一键免费开店、一键自动铺货、一键托管自动化运营、一键分享赚钱等傻瓜式便捷服务，高效链接消费者，同时重构品牌与消费者的关系，由供需关系转变为社群化关系，并最终沉淀为商家的私域流量池。
        </p> -->
      </div>
    </section>
    <footer>
      <div class="main container pd60">
        <div class="item">
          <h4>关于我们</h4>
          <p>公司介绍</p>
          <p>业务合作</p>
        </div>
        <div class="item">
          <h4>联系我们</h4>
          <p>邮箱：1487986688@qq.com</p>
          <p>电话（微信同名）：13410419943，周一至周日 9:30 - 22:30</p>
          <!-- <p>地址：深圳市福田区车公庙泰然八路安华工业区6栋613</p> -->
        </div>
        <div class="wx">
          <div class="wx-item">
            <img src="@/assets/images/wx-1.jpg" alt="" />
            <p>扫码关注公众号</p>
          </div>
          <div class="wx-item">
            <img src="@/assets/images/wx-2.jpg" alt="" />
            <p>业务合作</p>
          </div>
        </div>
      </div>
      <div class="copyright container pd60">
        <p>©2019-2024 深圳漠阳风科技有限公司 版权所有</p>
        <a href="http://beian.miit.gov.cn/">粤ICP备19157531号-2</a>
      </div>
    </footer>
  </div>
</template>
<script>
import "@/assets/css/base.css";
import "@/assets/css/style.css";
export default {};
</script>
